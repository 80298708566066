import AppListPage from "../../components/dashboard/list-page/list-page.vue";
import { dashboardSV } from "./dashboard.service.js";
import Loading from "../loading/loading.vue";
import ShareApp from "../../components/dashboard/share-app/share-app.vue";
import Language from "@/components/shared/language/language.vue";
import AddMember from "@/components/editor-v3/layout/layout-left-component/add-member/add-member.vue";
import Noti from "@/components/editor-v3/help-editor-block/noti/noti.vue";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick.min.js";

export default {
    name: "dashboard",
    components: {
        AppListPage,
        Loading,
        ShareApp,
        Language,
        AddMember,
        Noti
    },
    data: function() {
        return {
            contentnt: "",
            shownt: false,
            page: {},
            listPage: [],
            newName: "Untitled App",
            newType: "app",
            user: {},
            cateSelect: "",
            listCate: [],
            appIdClone: "",
            linkAppShare: "https://app.appon.vn/",
            idTemplateApp: "5d8c7aa2a91a17680bd15bda",
            listTemplateApp: [],
            listChildApp: [],
            menu: {
                select: 0
            },
            skip: 0,
            isScroll: true,
            cateId: "",
            search: "",
            description: "",
            showAds: false,
            scrollPosition: 0,
            v2: false,
            listBgColor: [
                "linear-gradient(126deg, rgba(231,221,255,1) 0%, rgba(200,179,236,1) 100%)",
                "linear-gradient(126deg, rgba(76,161,175,1) 0%, rgba(196,224,229,1) 100%)",
                "linear-gradient(126deg, rgba(189,195,199,1) 0%, rgba(44,62,80,1) 100%)",
                "linear-gradient(126deg, rgba(238,205,163,1) 0%, rgba(239,98,159,1) 100%)",
                "linear-gradient(to bottom, #acb6e5, #86fde8)",
                "linear-gradient(to bottom, #ffefba, #ffffff)",
                "linear-gradient(to bottom, #c9d6ff, #e2e2e2)",
                "linear-gradient(to right, #d9a7c7, #fffcdc)",
                "linear-gradient(to right, #1c92d2, #f2fcfe)"
            ],
            typeCreateApp: "",
            seeAllStatus: false,
            listAppExpand: [],
            nameExpand: "",
            showladi: false,
            timecheck: "",
            dataAddMember: {},
            emailMember: ""
        };
    },
    computed: {
        listTemplateAppActive() {
            if (!Array.isArray(this.listTemplateApp)) return [];
            let listApp = this.listTemplateApp
                .map((item) => {
                    let indexColor = Math.round(Math.random() * 4);
                    let color = this.listBgColor[indexColor];
                    item.backgroundCss = color;
                    return item;
                })
                .filter((item) => item.status === "active");
            return listApp;
        },
        listChildAppActive() {
            let listApp = this.listChildApp.map((item) => {
                item.templates
                    .map((app) => {
                        let indexColor = Math.round(Math.random() * 4);
                        let color = this.listBgColor[indexColor];
                        app.backgroundCss = color;
                        return app;
                    })
                    .filter((app) => app.status === "active");
                return item;
            });
            return listApp;
        },
        listAppExpandActive() {
            let listApp = this.listAppExpand
                .map((item) => {
                    let indexColor = Math.round(Math.random() * 4);
                    let color = this.listBgColor[indexColor];
                    item.backgroundCss = color;
                    return item;
                })
                .filter((item) => item.status === "active");
            return listApp;
        },
        listCateCompute() {
            return this.listCate.filter((item) => !item.parent);
        },
        nameCateSelect() {
            if (this.cateSelect === "") return "My app";
            if (this.cateSelect === this.idTemplateApp) return "All app";
            let nameCate = this.listCate.find((item) => item.id === this.cateSelect);
            return nameCate.name || "App";
        }
    },
    methods: {
        handleSearch(event) {
            const thisVue = this;

            if (!this.search) return;
            $("#dashboard-component .main-section").scrollTop(0);
            this.seeAllStatus = true;
            this.listAppExpand = [];
            this.nameExpand = "Search app";

            dashboardSV.search(
                JSON.stringify({
                    search: this.search
                }),
                (data) => {
                    thisVue.search = "";
                    event.target.blur();
                    thisVue.listAppExpand = data.data.page;
                }
            );
        },
        getTemplateApp() {
            console.log("chạy vào đây")
            const thisVue = this;
            if (this.idTemplateApp == "5d8c7aa2a91a17680bd15bda") {
                console.log("vào đây")
                dashboardSV.getAppInCategory($.param(), function(data) {
                    if (data && data.code === 200) {
                        thisVue.listTemplateApp = data.data;
                        if (thisVue.seeAllStatus) {
                            thisVue.listAppExpand = data.data;
                        }
                    } else {
                        thisVue.listTemplateApp = [];
                    }
                });
            } else {
                dashboardSV.getAppInCategory($.param({ id: this.idTemplateApp }), function(data) {
                    if (data && data.code === 200) {
                        thisVue.listTemplateApp = data.data;
                        if (thisVue.seeAllStatus) {
                            thisVue.listAppExpand = data.data;
                        }
                    } else {
                        thisVue.listTemplateApp = [];
                    }
                });
            }
            
        },
        shareApp(val) {
            this.linkAppShare = "https://app.appon.vn/" + val;
            $("#modal-share-app").modal("show");
        },
        showMoreCate() {
            $(".list-inline-item").show();
            $(".show-more-cate").hide();
        },
        showModalCreateApp() {
            this.typeCreateApp = "new-app";
            $("#modal-create-new-app").modal("show");
        },
        emitCloneApp(app) {
            this.typeCreateApp = "clone-app";
            this.appIdClone = app.id;
            this.newName = "Untitled App";
            $("#modal-create-new-app").modal("show");
        },
        emitCloneAppTemplate(app) {
            this.typeCreateApp = "clone-template";
            this.appIdClone = app.id;
            this.newName = "Untitled App";
            this.description = app.description;
            $("#modal-create-new-app").modal("show");
        },
        selectCategory(id) {
            const thisVue = this;

            this.seeAllStatus = false;
            this.listAppExpand = [];
            this.skip = 0;
            this.search = "";
            this.scrollPosition = 0;
            if (id === this.cateSelect) return;
            this.cateSelect = id;

            if (id && id.length > 1) {
                thisVue.cateId = id;
                thisVue.listTemplateApp = [];
                thisVue.listAppExpand = [];
                if (id == "5d8c7aa2a91a17680bd15bda") {
                    dashboardSV.getAppInCategory($.param({ skip: 0, limit: 20 }), function(data) {
                        if (data && data.code === 200) {
                            thisVue.listTemplateApp = data.data;
                        } else {
                            thisVue.listTemplateApp = [];
                            thisVue.$swal("", "Marketplace openning soon !");
                        }
                    });
                } else {
                    dashboardSV.getAppInCategory($.param({ category: id, skip: 0, limit: 20 }), function(data) {
                        if (data && data.code === 200) {
                            thisVue.listTemplateApp = data.data;
                        } else {
                            thisVue.listTemplateApp = [];
                            thisVue.$swal("", "Marketplace openning soon !");
                        }
                    });
                }
                
                this.getChildAppInCategory(id);
            } else {
                thisVue.cateId = "";
                thisVue.getAllPage();
                thisVue.listChildApp = [];
            }
        },
        getChildAppInCategory(id) {
            let thisVue = this;

            this.listChildApp = [];
            let listChildCate = this.listCate.filter((item) => item.parent === id);
            if (!listChildCate.length) return;

            listChildCate.forEach((item) => {
                let objectData = {};
                objectData.name = item.name;
                objectData.id = item.id;

                dashboardSV.getAppInCategory($.param({ category: item.id, skip: 0, limit: 20 }), function(data) {
                    if (data && data.code === 200) {
                        if (!data.data.length) return;
                        objectData.templates = data.data;
                        thisVue.listChildApp.push(objectData);
                    } else {
                        thisVue.$swal("", "Marketplace openning soon !");
                    }
                });
            });
        },
        handleClickSeeAll(val, name, id) {
            $("#dashboard-component .main-section").scrollTop(0);
            this.scrollPosition = 0;
            this.seeAllStatus = true;
            this.listAppExpand = $.extend(true, [], val);
            this.nameExpand = name;
            if (id) {
                this.cateId = id;
            }
        },
        createApp(id) {
            const thisVue = this;
            $("#loading-component").show();
            if (this.newName && this.newName.length > 0) {
                let dataCreate = {
                    name: this.newName,
                    description: "",
                    thumbnail: "",
                    type: "app",
                    status: "unactive",
                    id_template: id
                };
                dashboardSV.createNew(JSON.stringify(dataCreate), function(data) {
                    if (data && data.code === 200) {
                        $("#loading-component").hide();
                        // thisVue.$router.push({ path: "/dashboard/", query: {} });
                        setTimeout(function() {
                            //thisVue.$router.push({ path: "/editor-v4/", params: { id: data.data.id } });
                            thisVue.$router.replace({ name: "Editor4", params: { id: data.data.id } });
                        }, 100);
                    } else {
                        thisVue.$swal("", "Error !");
                        $("#loading-component").hide();
                    }
                    $("#modal-create-new-app").modal("hide");
                });
            } else {
                $("#loading-component").hide();
                this.$swal("", "Please enter Name app!");
            }
        },
        createAppByTemplate(id) {
            const thisVue = this;
            $("#loading-component").show();
            if (this.newName && this.newName.length > 0) {
                let dataCreate = {
                    name: this.newName,
                    description: this.description,
                    thumbnail: "",
                    type: "app",
                    status: "unactive",
                    id_template: id
                };
                dashboardSV.createNewByTemplate(JSON.stringify(dataCreate), function(data) {
                    $("#loading-component").hide();
                    if (data && data.code === 200) {
                        // thisVue.$router.push({ path: "/dashboard/", query: {} });
                        setTimeout(function() {
                            thisVue.$router.replace({ name: "Editor4", params: { id: data.data.id } });
                            //thisVue.$router.push({ name: "Editor4", params: { id: data.data.id } });
                        }, 100);
                    } else {
                        thisVue.$swal("", "Error !");
                        $("#loading-component").hide();
                    }
                    thisVue.newName = "";
                    $("#modal-create-new-app").modal("hide");
                });
            } else {
                $("#loading-component").hide();
                this.$swal("", "Please enter Name app!");
            }
        },
        getAllPage() {
            const thisVue = this;
            $("#loading-component").show();
            thisVue.listTemplateApp = [];
            thisVue.listAppExpand = [];
            thisVue.v2 = false;
            dashboardSV.getAll($.param({ skip: thisVue.skip, limit: 20 }), function(data) {
                if (data.code === 200) {
                    if (thisVue.seeAllStatus) {
                        thisVue.listAppExpand = data.data;
                    } else {
                        thisVue.listTemplateApp = data.data;
                    }
                    if (!data.data.length) {
                        thisVue.getTemplateApp();
                    }
                } else {
                    if (data.code === "OAuthException") {
                        thisVue.$router.push({ name: "Login" });
                    }
                }
                $("#loading-component").hide();
            });
        },
        deleteItemPage() {
            this.getAllPage();
        },
        editItemPage(id) {
            this.$router.push({ name: "Editor4", params: { id: id } });
            $("#loading-component").show();
        },
        getListCategory() {
            const thisVue = this;
            thisVue.v2 = false;
            dashboardSV.getListCategory({}, function(data) {
                if (data && data.code === 200) {
                    thisVue.listCate = data.data.filter(
                        (cate) => cate.type_second === "app" && cate.id != thisVue.idTemplateApp
                    );
                }
            });
        },
        getInforme() {
            const thisVue = this;
            dashboardSV.getInfo({}, function(data) {
                if (data && data.code === 200) {
                    localStorage.setItem("user", JSON.stringify(data.data));
                    thisVue.user = data.data;
                    if (data.data.reach < 490) {
                        thisVue.shownt = true;
                        thisVue.contentnt = "Tài khoản của bạn còn "+ data.data.reach+" UPD. Vui lòng mua thêm UPD để không làm gián đoạn ứng dụng!";
                    }
                }
            });
        },
        initLibrary() {
            $(".cs-1 .carousel__div").slick({
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 4,
                prevArrow: $(".cs-1 .paginator__div .prev__div"),
                nextArrow: $(".cs-1 .paginator__div .next__div")
            });
            $(".cs-2 .carousel__div").slick({
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 4,
                prevArrow: $(".cs-2 .paginator__div .prev__div"),
                nextArrow: $(".cs-2 .paginator__div .next__div")
            });
        },
        handleScroll() {
            console.log("chạy vô đây");
            const thisVue = this;
            let scrollPosition = $("#dashboard-component .main-section").scrollTop();
            let mainSectionHeight = $("#dashboard-component .see-all").outerHeight();

            if (!this.seeAllStatus) return;
            if (scrollPosition < this.scrollPosition) return;
            if (mainSectionHeight - 500 > scrollPosition) return;
            this.scrollPosition = scrollPosition;

            thisVue.skip += 20;
            if (!this.cateId) {
                dashboardSV.getAll($.param({ skip: thisVue.skip, limit: 20, v2: thisVue.v2 }), function(data) {
                    if (data.code === 200) {
                        thisVue.listAppExpand = [...thisVue.listAppExpand, ...data.data];
                        thisVue.listTemplateApp = thisVue.listAppExpand;
                    } else {
                        if (data.code === "OAuthException") {
                            thisVue.$router.push({ name: "Login" });
                        }
                    }
                });
            } else {
                dashboardSV.getAppInCategory(
                    $.param({ category: thisVue.cateId, skip: thisVue.skip, limit: 20 }),
                    function(data) {
                        if (data && data.code === 200) {
                            thisVue.listAppExpand = [...thisVue.listAppExpand, ...data.data];
                            thisVue.listTemplateApp = thisVue.listAppExpand;
                        }
                    }
                );
            }
        },
        toggleSection(type, val) {
            switch (type) {
                case "popup-user":
                    $("#dashboard-component .avatar-user .popup-action").toggle();
                    $("#dashboard-component #overlay").show();
                    break;
                case "hide-overlay":
                    $("#dashboard-component .avatar-user .popup-action").hide();
                    $("#dashboard-component #overlay").hide();
                    break;
            }
        },
        addMemberApp: function(val) {
            this.dataAddMember = val;
            $("#modal-add-member").modal("show");
        },
        getAppShareWithMe: function() {
            const thisVue = this;
            thisVue.v2 = true;
            $("#loading-component").show();
            thisVue.listTemplateApp = [];
            thisVue.listAppExpand = [];
            dashboardSV.getAll($.param({ skip: thisVue.skip, limit: 20, v2: thisVue.v2 }), function(data) {
                if (data.code === 200) {
                    if (thisVue.seeAllStatus) {
                        thisVue.listAppExpand = data.data;
                    } else {
                        thisVue.listTemplateApp = data.data;
                    }
                    if (!data.data.length) {
                        thisVue.getTemplateApp();
                    }
                } else {
                    if (data.code === "OAuthException") {
                        thisVue.$router.push({ name: "Login" });
                    }
                }
                $("#loading-component").hide();
            });
        }
    },
    mounted() {
        const thisVue = this;
        this.timecheck = new Date().getTime();
        this.getAllPage();
        this.getListCategory();
        this.getInforme();
        if (this.$route.params.id) {
            this.appIdClone = this.$route.params.id;
            this.typeCreateApp = "clone-app";
            this.newName = "Untitled App";
            $("#modal-create-new-app").modal("show");
        }
        this.initLibrary();
        $(window).on("wheel", _.debounce(thisVue.handleScroll, 200));
        $("#modal-create-new-app").on("hidden.bs.modal", function() {
            thisVue.newName = "";
        });
        setTimeout(function() {
            thisVue.showAds = true;
        }, 500);
    },
    destroyed() {
        $(window).off("wheel");
    }
};
